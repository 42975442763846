.container {
	width: calc(100% + 48px);
	display: flex;
	flex-direction: column;
	margin: -20px -24px;
	background: linear-gradient(40deg, #dadef1 0%, #fff5ef 50%) fixed !important;
}

@media (min-width: 1366px) {
	.container {
		width: calc(100% + 80px);
		margin: -32px -40px;
	}
}

.container > :nth-child(n) {
	max-width: 1024px;
	padding: 20px 24px;
}

@media (min-width: 1024px) {
	.container > :nth-child(n) {
		padding: 32px 40px;
	}
}

.title {
	margin: 0 auto;
	text-transform: uppercase;
	color: var(--color-primary);
	font-weight: 700;
	font-size: 30px;
}

@media (min-width: 768px) {
	.title {
		font-size: 34px;
	}
}

@media (min-width: 1024px) {
	.title {
		font-size: 40px;
	}
}

.block {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto 60px;
	position: relative;
}

@media (min-width: 768px) {
	.block {
		flex-direction: row;
	}

	.block > :nth-child(1) {
		margin-right: 60px;
	}
}

@media (min-width: 1024px) {
	.block {
		margin: 0 auto 40px;
	}
}

.blockImg {
	width: 90%;
	max-width: 400px;
	height: auto;
	margin-bottom: 28px;
	position: relative;

	filter: none; 
	-webkit-filter: blur(0px); 
	-moz-filter: blur(0px); 
	-ms-filter: blur(0px);
}

@media (min-width: 768px) {
	.blockImg {
		width: 0px;
		flex: 1 0 0;
	}
}

@media (min-width: 768px) {
	.blockTextContainer {
		width: 0px;
		flex: 1 0 0;
	}
}

.blockTitle {
	margin-bottom: 0.6em;
	font-size: 22px;
	line-height: 26px;
	color: var(--color-primary);
}

.blockText {
}

@media (min-width: 480px) {
	.blockTitle {
		font-size: 24px;
		line-height: 30px;
	}

	.blockText {
		font-size: 18px;
		line-height: 24px;
	}
}

@media (min-width: 768px) {
	.blockTitle {
		font-size: 26px;
		line-height: 30px;
	}

	.blockText {
		font-size: 20px;
		line-height: 28px;
	}
}

@media (min-width: 1024px) {
	.blockTitle {
		font-size: 30px;
		line-height: 36px;
	}

	.blockText {
		font-size: 20px;
		line-height: 28px;
	}
}

.block1 > .blockTextContainer {
	text-align: right;
}

.block1 .blockImg > img {
	width: 100%;
	height: auto;
	object-fit: contain;
}

.coin1 {
	position: absolute;
	top: 40%;
	left: 0px;
	transform: translateX(-80%);
	--anim-delay: 1s;
	--turn-time: 6s;
}
.coin2 {
	position: absolute;
	top: 0px;
	left: 26%;
	transform: translateY(-40%);
	--anim-delay: 0s;
	--turn-time: 10s;
}
.coin3 {
	position: absolute;
	top: 50%;
	right: 0%;
	--anim-delay: 0.4s;
	--turn-time: 8s;
}

.block2 > .blockImg {
	width: 70%;
}

@media (min-width: 768px) {
	.block2 > .blockImg {
		order: 1;
		width: 0px;
		flex: 0.6 0 0;
		margin-right: 0px !important;
		margin-left: 60px !important;
	}
}

.block3 {
	width: 100%;
	max-width: var(--maxwidth) !important;
	padding: 80px 24px 60px !important;
	margin-bottom: 0 !important;

	background: var(--color-primary);
	color: white;
}

.block3 .blockTextContainer {
	margin-bottom: 28px;
}

.block3 .blockTitle {
	color: white;
}

.block3 > .blockImg {
	margin-bottom: 0;
}

.coinContainer {
	display: flex;
	flex-direction: column; /* make main axis vertical */
	justify-content: center; /* center items vertically, in this case */
	align-items: center; /* center items horizontally, in this case */

	--coin-diameter: 40px;
	--coin-color: #fdda51;
	--turn-time: 8s;
	--coin-back: url("/src/img/pictures/dollar.svg");
}

@media (min-width: 450px) {
	.coinContainer {
		--coin-diameter: 50px;
	}
}

@media (min-width: 900px) {
	.coinContainer {
		--coin-diameter: 60px;
	}
}

@media (min-width: 1280px) {
	.coinContainer {
		--coin-diameter: 70px;
	}
}

.coin {
	position: relative;
	width: var(--coin-diameter);
	height: var(--coin-diameter);
	-webkit-transform-style: preserve-3d;
	-webkit-perspective: 1000px;
	animation: rotate3d var(--turn-time) linear infinite;
	animation-delay: var(--anim-delay);
	transform-style: preserve-3d;
	transition: all 0.3s;
}

.oval,
.oval_back {
	position: absolute;
	width: var(--coin-diameter);
	height: var(--coin-diameter);
	border-radius: 50%;
	overflow: hidden;
	background: linear-gradient(90deg, #fdda51 0%, #fca741 100%);
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.oval:after,
.oval_back:after {
	content: "";
	position: absolute;
	left: calc(var(--coin-diameter) / -2);
	bottom: 100%;
	display: block;
	height: calc(var(--coin-diameter) / 1.5);
	width: calc(var(--coin-diameter) * 2);
	background: #fff;
	opacity: 0.4;
	animation: shine linear calc(var(--turn-time) / 2) infinite;
	animation-delay: var(--anim-delay);
}

.oval_back {
	transform: rotateY(180deg);
}

.inner_oval {
	box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.2);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: bold;
	padding-top: 0.125rem;
	width: 170px;
	height: 170px;
	background-size: 80%;
	background-repeat: no-repeat;
	background-image: var(--coin-back) !important;
	background-position: center center !important;
}

@keyframes rotate3d {
	0% {
		transform: perspective(1000px) rotateY(0deg);
	}

	100% {
		transform: perspective(1000px) rotateY(360deg);
	}
}

@keyframes shine {
	0%,
	15% {
		transform: translateY(calc(var(--coin-diameter) * 2)) rotate(-40deg);
	}
	50% {
		transform: translateY(calc(-1 * var(--coin-diameter))) rotate(-40deg);
	}
}

.block3 > :nth-child(n) {
	transform: translateY(100%);
	transition: transform 1.6s;
}

.block3Anim > :nth-child(n) {
	transform: translateY(0) !important;
}
