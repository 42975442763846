.container {
	display: flex;
	flex-direction: column;
	padding: 20px 30px;
}

.qrBlock {
	width: max-content;
	margin: 24px auto 48px;
}

.linkBlock {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.linkInput {
	width: 0;
	flex: 1 0 0;
	padding: 0;
	border: none;
	background: none;
	cursor: default;
}

.linkInput:focus {
	outline: none;
}

.copyBtn {
	width: 80px;
	padding: 4px 0;
	margin-left: 16px;

	background: none;
	border: 1px solid dodgerblue;
	border-radius: 4px;

	text-transform: uppercase;
	color: dodgerblue;
	cursor: pointer;
}
