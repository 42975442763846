.container {}

@media (min-width: 768px) {
	.container {
		padding: 16px;
		box-shadow: 0px 0px 1px rgb(0 0 0 / 20%), 0px 2px 10px rgb(0 0 0 / 8%), 0px 1px 1px rgb(0 0 0 / 16%);
	}
}

.seeAllContainer {
	cursor: pointer;
}

.seeAllBtn {
	display: flex;
	align-items: center;

	background: 0;
	border: 0;

	font-size: 20px;
	font-weight: 600;

	cursor: pointer;
}

.seeAllBtn:after {
	content: '';
	width: 20px;
	height: 20px;
	display: block;
	margin-left: 12px;

	transform: rotate(90deg);

	background: url("img/seeAllIcon.svg") center / contain no-repeat;
}

.seeAllBtnActive:after {
	transform: rotate(-90deg) !important;
}

.moreInfoContainer {
	display: none;
}

.chartInfoContainer {
	/*display: flex;*/
}

@media (min-width: 768px) {
	.chartInfoContainer {
		max-width: 580px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.chartInfoContainer p {
		margin: 0;
	}
}

.infoText {
	margin-bottom: 10px;

	font-size: 15px;
	color: rgb(226, 226, 226);
}

.portfolioContainer {
}
