.productList {
  /* display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px; */

  display: flex;
  flex-direction: column;
  gap: 10px;
  grid-gap: 10px;
}

@media screen and (min-width: 1000px){
  .productList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* grid-template-columns: 1fr 1fr 1fr; */
  }
}
