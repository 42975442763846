.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	/*padding: 16px;*/
}

.sortContainer {
	display: flex;
	align-items: center;
	margin-bottom: 24px;
}

.sortLabel {
	margin: 0 6px 0 0;
	text-transform: uppercase;
}

.newGoalBtn {
	width: 100%;
	padding: 16px 0;

	background: var(--color-primary);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	border: none;
	border-radius: 16px;

	color: white;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;

	cursor: pointer;
}

.goalsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-gap: 20px;
}

@media (min-width:375px) {
	.goalsContainer{
		grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
	}
}

.addGoalCard {
  min-height: 100px;
  padding: 10px;
  border-radius: 8px;
  background: #f8f8f88a;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(7px);
	background: url('../../img/plus.svg') center / 30% 30% no-repeat;
	border: 0;
	cursor: pointer;
}

.addGoalCard:hover {
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.16), 0 1px 1px rgba(0, 0, 0, 0.3);
}
