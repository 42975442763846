.main {
  width: 100%;
}

.name {
  font-size: 12px;
  margin-bottom: 2px;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: center;
}

.number {
  display: block;
}

.slider {
  width: 100%;
  margin-bottom: 10px;
  margin-top: 0;
}
