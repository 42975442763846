.main {
	display: block;
}

.container {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

@media (min-width: 768px) {
	.container {
		display: grid;
		grid-template-columns: 350px 1fr;
		grid-template-rows: auto auto auto;
	}
}

@media (min-width: 1280px) {
	.container {
		grid-template-columns: 420px 1fr;
	}
}

.titleContainer{
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	margin-bottom: 16px;
}

.title {
	text-align: center;
}

.qrCodeIcon{
	width: 30px;
	margin-left: 20px;
}

.fieldsContainer {
	width: 100%;
}

@media (max-width: 575px) {
	.formItem {
		margin-bottom: 38px;
	}
}

.fieldsContainer :global(.ant-form-item-label) {
	padding: 0 0 4px;
}

@media (min-width: 768px) {
	.fieldsContainer {
		height: fit-content;
		box-shadow: 0px 0px 1px rgb(0 0 0 / 20%), 0px 2px 10px rgb(0 0 0 / 8%), 0px 1px 1px rgb(0 0 0 / 16%);
		padding: 16px;
	}
}

.fieldInput {
	width: 100%;
	display: flex;
}

@media (min-width: 575px) {
	.fieldInput {
		max-width: 50vw;
		margin-left: auto;
		margin-right: 0;
		align-self: end;
	}
}

@media (min-width: 768px) {
	.fieldInput {
		max-width: 170px;
	}
}

@media (min-width: 1280px) {
	.fieldInput {
		max-width: 200px;
	}
}

.infoText {
	margin-bottom: 10px;

	font-size: 15px;
	color: rgb(226, 226, 226);
}

.sharpeSlider, .riskSlider, .dailyDrawdownSlider {
	margin: 0 0 0 auto;
}

.sharpeSlider :nth-child(1) {
	background: linear-gradient(0.25turn,
	#f04b4b 35.714%,
	#ee854d 35.714%,
	#ee854d 64.285%,
	#e5bb52 64.285%,
	#e5bb52 85.714%,
	#59b89e 85.714%,
	#59b89e 100%
	);
	/*#59b89e 35.714%,*/
	/*#e5bb52 35.714%,*/
	/*#e5bb52 64.285%,*/
	/*#ee854d 64.285%,*/
	/*#ee854d 85.714%,*/
	/*#f04b4b 85.714%,*/
	/*#f04b4b 100%*/
}

.sharpeSlider :nth-child(2) {
	background: none !important;
}

.riskSlider :nth-child(1) {
	background: linear-gradient(0.25turn,
	#59b89e 30%,
	#e5bb52 30%,
	#e5bb52 60%,
	#ee854d 60%,
	#ee854d 80%,
	#f04b4b 80%,
	#f04b4b 100%
	);
}

.riskSlider :nth-child(2) {
	background: none !important;
}

.dailyDrawdownSlider :nth-child(1) {
	background: linear-gradient(0.25turn,
	#59b89e 25%,
	#e5bb52 25%,
	#e5bb52 50%,
	#ee854d 50%,
	#ee854d 75%,
	#f04b4b 75%,
	#f04b4b 100%
	);
}

.dailyDrawdownSlider :nth-child(2) {
	background: none !important;
}

.accumulationChart {
}

.fundsChart {
}

@media (min-width: 768px) {
	.fundsChart {
		grid-area: 2 / 1 / 2 / 3;
	}
}

.submitBtn {
	text-transform: uppercase;
}

@media (min-width: 768px) {
	.submitBtn {
		grid-area: 3 / 1 / 3 / 3;
	}
}
