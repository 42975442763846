.selectBox {
  display: flex;
  width: 100%;
}

.select {
  /*width: 276px;*/
  /*width: 100%;*/
  width: calc(100% - 42px);
  max-width: calc(100% - 42px);
  margin-bottom: 8px;
}

.item {
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
  width: 100%;
  margin-bottom: 8px;
}

.item__name {
  width: 196px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.delete {
  /*margin-left: 4px;*/
  color: #ff0000;
  position: absolute;
  margin: auto;
  right: 2px;
  top: 0;
  bottom: 0;
  height: 14px;
}
