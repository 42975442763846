.header {
	width: 100%;
	position: fixed;
	padding: 0px 15px;
	z-index: 2;
	background: white;
	box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.16);
}

.headerContent {
	width: 100%;
	height: 100%;
	max-width: var(--maxwidth);
	display: flex;
	align-items: center;
	margin: 0 auto;
}

.logoLink {
	height: 100%;
	display: flex;
	padding: 10px 0;
	margin-right: 20px;
}

.logo {
	height: 100%;
	object-fit: contain;
}

.menuContainer {
	background: 0;
}

.loginBtn {
	margin: auto 0 auto auto;
}

.userMenuBtn {
	display: flex;
	align-items: center;
	overflow: hidden;
	background: none;
	border: none;
	cursor: pointer;
}

.userMenu {
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.username{
	margin: 0;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.themeBtn {
	height: 100%;
	object-fit: contain;
}

.themeResetBtn {
	display: block;
	margin-left: auto;
	border: 1px solid var(--color-primary);
}
