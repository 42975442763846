.container {
	padding: 10px;
	position: relative;

	background: #f8f8f88a;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08), 0 1px 1px rgba(0, 0, 0, 0.16);
	cursor: pointer;
}

.container:hover {
	box-shadow: 0 2px 20px rgba(0, 0, 0, 0.16), 0 1px 1px rgba(0, 0, 0, 0.3);
}

.title {
	display: inline-block;
	margin: 0 12px 12px 0;
}

.deleteGoalBtn {
	position: absolute;
	top: 10px;
	right: 10px;

	font-size: 14px;

	transition: color 0.2s;
}

.deleteGoalBtn:hover {
	color: red;
}

.progressBarContainer {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 12px;
}

.progressBarContainer > span {
	margin-bottom: 2px;
	line-height: 1em;
}

.progressBar {
	width: 0;
	height: 10px;
	flex: 1 0 0;
	margin: 0 6px;
	border-radius: 6px;
	border: 1px solid rgba(0, 0, 0, 0.4);
}

.paymentRow {
	display: flex;
	justify-content: space-between;
}

.paymentsBtn {
	background: none;
	border: none;

	text-transform: uppercase;
	font-size: 10px;
	font-weight: 500;
	letter-spacing: 1px;
	color: #4fa7ff;

	cursor: pointer;
}

.paymentsBtn:hover {
	color: #007fff;
}

.goalCardPaymentModal {
	top: 40px;
}

:global(.ant-modal-body) {
	padding: 0;
}
